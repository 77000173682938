import { PureComponent } from 'react';

// Material UI imports
import {
  Typography,
  ButtonGroup,
  Button,
  Avatar,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  withStyles
} from '@material-ui/core';

// Import lab components
import { Skeleton /* Rating */ } from '@material-ui/lab';

// React routing link
import { withRouter } from 'react-router-dom';

// Portlet items
import { Portlet, PortletContent, PortletFooter } from 'components';

// Name initials helper
import { getInitials } from 'helpers';

// DateTime helpers
import { DateTime } from 'luxon';

// Style
import styles from './styles';

class TutorBookingCard extends PureComponent {
  constructor(props) {
    super(props);

    // Initialize the component's state
    const { loading, bookings } = props;
    this.state = {
      selected: !loading && bookings.length > 0 ? bookings[0] : null
    };
  }

  componentDidUpdate(prevProps) {
    const { loading, bookings } = this.props;

    // Auto-select a new booking
    if (prevProps.bookings !== bookings) {
      this.setState({
        selected: !loading && bookings.length > 0 ? bookings[0] : null
      });
    }
  }

  render() {
    const { classes, loading, bookings, tutor, onBook } = this.props;
    const { selected } = this.state;
    const { image, name, isPte } = bookings[0]?.profile.tutor;

    // Render the tutor booking card
    return (
      <Portlet className={classes.root}>
        <PortletContent className={classes.content}>
          {loading ? (
            <>
              <Skeleton variant="circle" className={classes.avatar} />
            </>
          ) : (
            <>
              <div className={'left_sec'}>
                <Avatar src={image} alt={name} className={classes.avatar}>
                  <Typography variant="h3" style={{ color: 'white' }}>
                    {getInitials(name || '')}
                  </Typography>
                </Avatar>
                <Typography
                  variant="h6"
                  style={{ textAlign: 'center', marginTop: '8px' }}>
                  {isPte ? 'PTE Workplace' : 'Workplace'}
                </Typography>
              </div>

              <div className={classes.info}>
                <Typography variant="h3">{name}</Typography>
                <FormControl
                  variant="filled"
                  className={classes.date}
                  fullWidth>
                  <InputLabel
                    className={classes.subtitle}
                    htmlFor={`booking-date-${tutor}`}>
                    Time
                  </InputLabel>
                  <Select
                    value={selected}
                    onChange={(event) => {
                      this.setState({ selected: event.target.value });
                    }}
                    inputProps={{
                      name: 'age',
                      id: `booking-date-${tutor}`
                    }}>
                    {bookings.map((booking) => {
                      // Format the timestamp
                      const time = DateTime.fromMillis(booking.when);
                      const timeFormatted = time.toLocaleString(
                        DateTime.TIME_SIMPLE
                      );
                      const timeFormattedAmpm = time.toFormat('h:mm a');

                      // Render the menu item
                      return (
                        <MenuItem key={booking.when} value={booking}>
                          {timeFormatted} ({timeFormattedAmpm})
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </>
          )}
        </PortletContent>
        <PortletFooter className={classes.buttonFill} noPadding>
          <ButtonGroup fullWidth variant="text">
            <Button
              disabled={loading}
              onClick={() => {
                const { history } = this.props;
                history.push(`/student/profile/${tutor}`);
              }}>
              view teacher
            </Button>
            <Button
              disabled={loading}
              color="primary"
              onClick={() => {
                onBook(selected);
              }}>
              book
            </Button>
          </ButtonGroup>
        </PortletFooter>
      </Portlet>
    );
  }
}

export default withRouter(withStyles(styles)(TutorBookingCard));
