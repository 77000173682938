import { useState } from 'react';

// Material UI imports
import { Divider, Box, Tabs, Tab, makeStyles } from '@material-ui/core';

// Style
import styles from './styles';
import './styles.scss';

const useStyles = makeStyles(styles);

function TabPanel(props) {
  const { children, display, view } = props;

  return (
    <Box
      style={{
        display: display ? 'flex' : 'none',
        flexDirection: view ? 'row' : 'column',
        flexWrap: view ? 'wrap' : 'unset',
        flexGrow: 1
      }}>
      {children}
    </Box>
  );
}

function TabContainer(props) {
  // Grab the tabs from the component's props
  const { className, tabs, initial, index, onChange, view } = props;
  const classes = useStyles();

  // Initialize the container's state
  const [tabIndex, updateTabIndex] = useState(initial || 0);

  // Tab change handler
  const handleChange = (event, newValue) => {
    if (index !== null && index !== undefined && onChange) {
      onChange(newValue);
    } else {
      updateTabIndex(newValue);
      onChange && onChange(newValue);
    }
  };

  // Props generator
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  };

  // Make sure our tab data is an array
  if (Array.isArray(tabs)) {
    return (
      <div className={`${classes.root} ${className}`}>
        <Tabs
          className="booking-tabs"
          value={index || tabIndex}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="simple tabs example">
          {tabs.map((tab, index) => (
            <Tab
              disableRipple
              key={tab.label}
              label={tab.label.toUpperCase()}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
        <Divider className={classes.divider} />
        {tabs.map((tab, mapIndex) => (
          <TabPanel
            view={view}
            key={`${tab.label}-panel`}
            display={(index || tabIndex) === mapIndex}>
            {tab.component}
          </TabPanel>
        ))}
      </div>
    );
  }

  return null;
}

export default TabContainer;
