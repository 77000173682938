import { useContext, forwardRef } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';

// Externals
import classNames from 'classnames';

// Authentication provider & Firebase
import { AuthContext } from 'services/auth';

// Shared helpers
import { getInitials } from 'helpers';

// Material components
import {
  // Button,
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
  // CircularProgress,
  Chip,
  withStyles
} from '@material-ui/core';

// Info icon
import {
  InfoRounded as InfoIcon,
  BugReportRounded as BugIcon,
  QuestionAnswerRounded as FAQ
} from '@mui/icons-material';

// Material UI lab
import { Skeleton } from '@material-ui/lab';

// Sidebar items
import getSidebarItems from 'services/views';

// Cleanup exports
import compose from 'recompose/compose';

// App config
import AppConfig from 'config';

// Component styles
import styles from './styles';
import { DateTime } from 'luxon';

function Sidebar(props) {
  // Grab values out of the component's properties
  const {
    classes,
    className,
    itemClicked,
    profile,
    profileError // ,
    // onProfileRetry
  } = props;

  const auth = useContext(AuthContext);

  const { displayName, photoURL } = auth.user;

  // Get the root classname
  const rootClassName = classNames(classes.root, className);

  // Create a ref component for the sidebar links
  // eslint-disable-next-line react/display-name
  const SidebarNavLink = forwardRef((newProps, ref) => (
    <NavLink onClick={itemClicked} innerRef={ref} {...newProps} />
  ));

  return (
    <nav className={rootClassName}>
      <div className={classes.logoWrapper}>
        <Link className={classes.logoLink} to={`/${auth.role}/dashboard`}>
          <img
            width="75px"
            height="75px"
            alt="e-English logo"
            className={classes.logoImage}
            src="/images/logos/new-logo.png"
          />
        </Link>
      </div>
      <Divider className={classes.logoDivider} />
      <div className={classes.profile}>
        <Avatar alt={displayName} className={classes.avatar} src={photoURL}>
          <Typography variant="h3" style={{ color: 'white' }}>
            {getInitials(displayName && displayName)}
          </Typography>
        </Avatar>
        <div className={classes.profileDetails}>
          <Typography className={classes.nameText} variant="h5">
            {displayName}
          </Typography>
          <Typography className={classes.bioText} variant="caption">
            {
              {
                student: 'Student',
                tutor: 'Teacher',
                admin: 'Administrator'
              }[auth.role]
            }
          </Typography>
        </div>
      </div>
      <Divider className={classes.profileDivider} />
      {(() => {
        // Make sure we're a student
        if (auth.role === 'student') {
          if (profileError) {
            // Return the error message and a retry button.
            return (
              <div className={classes.tokenInfo}>
                <Typography variant="h6">{profileError}</Typography>
                {/* <Button
                  variant="outlined"
                  className={classes.retryButton}
                  onClick={onProfileRetry}>
                  Retry
                </Button> */}
                <Divider className={classes.profileDivider} />
              </div>
            );
          }

          // eslint-disable-next-line no-constant-condition
          if (!profile) {
            // If the profile is null (aka we're making the request), show
            // a progress spinner
            return (
              <>
                <div className={classes.tokenInfo}>
                  <Skeleton className={classes.skeletonChip}>
                    <Chip size="small" label="10 Lesson Tokens" />
                  </Skeleton>
                  <Skeleton
                    className={`${classes.skeletonChip} ${classes.subBadge}`}>
                    <Chip size="small" label="No Subscription" />
                  </Skeleton>
                </div>
                <Divider className={classes.profileDivider} />
              </>
            );
          }

          if (profile) {
            // Format the subscription name
            const sub = profile.stripe.subscription;

            const freeTrialActivated = profile.stripe.freeTrialActivated;
            console.log('profile', profile);
            // Return the user's information
            return (
              <>
                <div className={classes.tokenInfo}>
                  {sub?.currentSubscription &&
                  sub?.currentSubscription?.categories?.length > 0 ? (
                    <>
                      {/* get different subscription categories */}
                      {(() => {
                        const oneONoneWorkPlace =
                          sub?.currentSubscription?.categories?.find(
                            (f) =>
                              f.priceId === 'price_1QVCTaHns6zXPgc8CyRjvfHI'
                          );
                        const oneONonePte =
                          sub?.currentSubscription?.categories?.find(
                            (f) =>
                              f.priceId === 'price_1QVCUcHns6zXPgc8e7zIwaX8'
                          );
                        const groupWorkPlace =
                          sub?.currentSubscription?.categories?.find(
                            (f) =>
                              f.priceId === 'price_1QVCVoHns6zXPgc8xVuXjOnu'
                          );
                        const groupPte =
                          sub?.currentSubscription?.categories?.find(
                            (f) =>
                              f.priceId === 'price_1QVCWmHns6zXPgc8FVBDzXEl'
                          );
                        return (
                          <>
                            {oneONoneWorkPlace || groupWorkPlace ? (
                              <div className={classes.subscription}>
                                <div className={classes.subscriptionTitle}>
                                  <Typography variant="h5">
                                    Work English
                                  </Typography>
                                </div>
                                {oneONoneWorkPlace ? (
                                  <>
                                    <Typography
                                      className={classes.subscription_name}
                                      variant="h6">
                                      {profile.data.lessonTokens || 0}{' '}
                                      One-on-One Lessons
                                    </Typography>
                                    <Typography
                                      variant="p"
                                      color="textSecondary">
                                      Expires{' '}
                                      {oneONoneWorkPlace?.expiredAt &&
                                        DateTime.fromSeconds(
                                          oneONoneWorkPlace?.expiredAt
                                        ).toFormat('dd LLL yyyy')}
                                    </Typography>
                                  </>
                                ) : null}
                                {groupWorkPlace ? (
                                  <>
                                    {oneONoneWorkPlace ? (
                                      <Divider className={classes.subdevider} />
                                    ) : null}
                                    <Typography
                                      className={classes.subscription_name}
                                      variant="h6">
                                      {profile.data.groupLessonTokens || 0}{' '}
                                      Group Sessions
                                    </Typography>
                                    <Typography
                                      variant="p"
                                      color="textSecondary">
                                      Expires{' '}
                                      {groupWorkPlace?.expiredAt &&
                                        DateTime.fromSeconds(
                                          groupWorkPlace?.expiredAt
                                        ).toFormat('dd LLL yyyy')}
                                    </Typography>
                                  </>
                                ) : null}
                              </div>
                            ) : null}
                            {oneONonePte || groupPte ? (
                              <div
                                className={classes.subscription}
                                style={{ marginTop: '10px' }}>
                                <div className={classes.subscriptionTitle}>
                                  <Typography variant="h5">
                                    PTE English
                                  </Typography>
                                </div>
                                {oneONonePte ? (
                                  <>
                                    <Typography
                                      className={classes.subscription_name}
                                      variant="h6">
                                      {profile.data.PTELessonTokens || 0}{' '}
                                      One-on-One Lessons
                                    </Typography>
                                    <Typography
                                      variant="p"
                                      color="textSecondary">
                                      Expires{' '}
                                      {oneONonePte?.expiredAt &&
                                        DateTime.fromSeconds(
                                          oneONonePte?.expiredAt
                                        ).toFormat('dd LLL yyyy')}
                                    </Typography>
                                  </>
                                ) : null}
                                {groupPte ? (
                                  <>
                                    {oneONonePte ? (
                                      <Divider className={classes.subdevider} />
                                    ) : null}

                                    <Typography
                                      className={classes.subscription_name}
                                      variant="h6">
                                      {profile.data.PTEGroupLessonTokens || 0}{' '}
                                      Group Sessions
                                    </Typography>
                                    <Typography
                                      variant="p"
                                      color="textSecondary">
                                      Expires{' '}
                                      {groupPte?.expiredAt &&
                                        DateTime.fromSeconds(
                                          groupPte?.expiredAt
                                        ).toFormat('dd LLL yyyy')}
                                    </Typography>
                                  </>
                                ) : null}
                              </div>
                            ) : null}
                          </>
                        );
                      })()}
                    </>
                  ) : freeTrialActivated ? (
                    <Chip
                      size="small"
                      className={`${classes.infoChip}`}
                      label="Free Trial"
                    />
                  ) : (
                    <Chip
                      size="small"
                      className={`${classes.infoChip}`}
                      label="No Subscription"
                    />
                  )}
                </div>
                <Divider className={classes.profileDivider} />
              </>
            );
          }
        }

        return null;
      })()}
      <List component="div" disablePadding>
        {getSidebarItems(auth.role, auth.isDevBuild).map((item) => {
          if (item.icon !== undefined && item.text !== undefined) {
            return (
              <ListItem
                activeClassName={classes.activeListItem}
                className={classes.listItem}
                component={SidebarNavLink}
                to={item.route}
                key={item.route}>
                <ListItemIcon className={classes.listItemIcon}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary={item.text}
                />
              </ListItem>
            );
          }
          return null;
        })}
      </List>
      <Divider className={classes.listDivider} />
      <List
        component="div"
        disablePadding
        subheader={
          <ListSubheader className={classes.listSubheader}>
            Support
          </ListSubheader>
        }>
        <ListItem
          className={classes.listItem}
          component="a"
          href="mailto:support@e-english.com.au"
          target="_blank">
          <ListItemIcon className={classes.listItemIcon}>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.listItemText }}
            primary="Contact Us"
          />
        </ListItem>
        {auth.role !== 'admin' && (
          <ListItem
            className={classes.listItem}
            component="a"
            href={
              auth.role === 'tutor'
                ? 'https://docs.google.com/forms/d/e/1FAIpQLSc1abmrnw9R9l4-QqAUynwxwlPi2CCKhJHQjliy3luOoCZbsA/viewform?usp=sharing'
                : 'https://docs.google.com/forms/d/e/1FAIpQLSextGBWDBn1kgZWaKXG88eskrDceITjK5ryqbqCl2QNTjtTUQ/viewform?usp=sharing'
            }
            target="_blank">
            <ListItemIcon className={classes.listItemIcon}>
              <BugIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Submit feedback"
            />
          </ListItem>
        )}
        {auth.role == 'tutor' && (
          <ListItem
            className={classes.listItem}
            component="a"
            href={
              'https://firebasestorage.googleapis.com/v0/b/eng-tutor-app-dev.appspot.com/o/tutorfaqs%2FFAQ%20TUTOR_v1.pdf?alt=media&token=5e4ee3bf-5566-4f0f-9600-8ff461b600df'
            }
            target="_blank">
            <ListItemIcon className={classes.listItemIcon}>
              <FAQ />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="FAQ"
            />
          </ListItem>
        )}
      </List>
      <Divider className={classes.listDivider} />
      <ListSubheader className={classes.listVersionSubheader}>
        Build ID: {AppConfig.buildId}
      </ListSubheader>
    </nav>
  );
}

export default compose(withRouter, withStyles(styles))(Sidebar);
