import { PureComponent } from 'react';

// Material UI imports
import {
  Typography,
  ButtonGroup,
  Button,
  Avatar,
  withStyles,
  Popover
} from '@material-ui/core';

import { AssignmentRounded as LessonIcon } from '@mui/icons-material';

// Import lab components
import { Skeleton /* Rating */ } from '@material-ui/lab';

// React routing link
import { withRouter } from 'react-router-dom';

// Portlet items
import {
  GroupSessionBookingInfo,
  Portlet,
  PortletContent,
  PortletFooter,
  PortletHeader
} from 'components';

// Name initials helper
import { getErrorMessage, getInitials } from 'helpers';

// DateTime helpers
import { DateTime } from 'luxon';

// Style
import styles from './styles';

class GroupSessionTutorBookingCard extends PureComponent {
  constructor(props) {
    super(props);

    // Initialize the component's state
    const { loading, bookings } = props;
    this.state = {
      selected:
        !loading && bookings.length > 0 ? bookings[0].groupSessionId : null,
      material: null,
      course: null,
      lesson: null,
      popoverAnchor: null
    };

    this.ready = false;
  }

  async componentDidMount() {
    this.ready = true; // Component is now mounted
    await this.fetchMaterials();
  }

  componentDidUpdate(prevProps) {
    const { loading, bookings } = this.props;
    if (prevProps.bookings !== bookings) {
      this.setState({
        selected:
          !loading && bookings.length > 0 ? bookings[0].groupSessionId : null
      });
      this.fetchMaterials();
    }
  }

  componentWillUnmount() {
    this.ready = false;
  }
  // Function to fetch material, course, and lesson data
  async fetchMaterials() {
    const { bookings, auth } = this.props;
    const { info } = bookings[0] || {};

    if (!info?.topic) return;

    try {
      const [materials, courses] = await Promise.all([
        auth.api.materials.getMaterials(),
        auth.api.materials.getCourses(info.topic.material)
      ]);

      const material = materials.find(
        (entry) => entry.id === info.topic.material
      );
      const course = courses.find((entry) => entry.id === info.topic.course);

      if (course && material) {
        const lesson = course.lessons.find(
          (lesson) => lesson.id === info.topic.lesson
        );

        if (this.ready) {
          this.setState({
            material,
            course,
            lesson
          });
        }
      } else {
        throw new Error('Material or course not found');
      }
    } catch (error) {
      if (this.ready) {
        this.setState({
          error: getErrorMessage(error)
        });
      }
    }
  }

  // Event handler to open popover
  handlePopoverOpen = (event) => {
    this.setState({
      popoverAnchor: event.currentTarget
    });
  };

  // Event handler to close popover
  handlePopoverClose = () => {
    this.setState({
      popoverAnchor: null
    });
  };

  render() {
    const { classes, loading, bookings, tutor, onBook } = this.props;
    const { selected, course, lesson, popoverAnchor } = this.state;
    const { image, name, isPte } = bookings[0]?.tutor;
    const { topic } = bookings[0].info;
    const lessonCount = bookings[0].lessonCount;
    const bookingTime = bookings[0].when;

    // Format the time
    const time = DateTime.fromMillis(bookingTime);

    // Format the time to the desired format: "Tuesday 12-Nov-2024"
    const commencingTime = time.toFormat('cccc dd-MMM-yyyy');

    // Format the day and time, appending "'s" for possessive form
    const dayFormatted = time.toFormat('cccc');
    const timeFormatted = time.toFormat('h:mm a');
    const formattedTime = `${dayFormatted} at ${timeFormatted}`;

    const groupSessionDetail = {
      lessonCount: lessonCount,
      courseName: course?.name,
      lessonLevel: lesson?.level,
      groupSessionId: selected,
      when: bookingTime
    };

    // Render the tutor booking card
    return (
      <>
        <Popover
          id={popoverAnchor ? 'simple-popover' : undefined}
          open={Boolean(popoverAnchor)}
          anchorEl={popoverAnchor}
          onClose={this.handlePopoverClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          PaperProps={{
            style: {
              maxHeight: '300px',
              overflow: 'auto'
            }
          }}>
          <GroupSessionBookingInfo bookings={bookings} />
        </Popover>
        <Portlet className={classes.root}>
          <PortletHeader>
            <Typography variant="h5">
              {course && lesson
                ? `${course.name} Level ${lesson.level}`
                : topic?.course || 'Course not available'}
            </Typography>
            <Typography variant="h5">{`${lessonCount} Lessons`}</Typography>
          </PortletHeader>
          <PortletContent className={classes.content}>
            {loading ? (
              <>
                <Skeleton variant="circle" className={classes.avatar} />
              </>
            ) : (
              <>
                <Avatar src={image} alt={name} className={classes.avatar}>
                  <Typography variant="h3" style={{ color: 'white' }}>
                    {getInitials(name || '')}
                  </Typography>
                </Avatar>
                <div className={classes.info}>
                  <Typography
                    variant="h6"
                    style={{ textAlign: 'center', marginTop: '8px' }}>
                    {isPte ? 'PTE Workplace' : 'Workplace'}
                  </Typography>
                  <Typography variant="h3">{name}</Typography>

                  {/* Display formatted time */}
                  <Typography variant="h6" className={classes.subtitle}>
                    {formattedTime}{' '}
                  </Typography>
                </div>
                <div style={{ margin: '10px 0px' }}>
                  <Button
                    size="small"
                    className={classes.gutterRight}
                    variant="contained"
                    onClick={(ev) => this.handlePopoverOpen(ev)}
                    startIcon={<LessonIcon />}>
                    View Lessons
                  </Button>
                </div>

                <div className={classes.commencingBadge}>
                  <Typography className={classes.commencingTime}>
                    Commencing {commencingTime}
                  </Typography>
                </div>
              </>
            )}
          </PortletContent>
          <PortletFooter className={classes.buttonFill} noPadding>
            <ButtonGroup fullWidth variant="text">
              <Button
                disabled={loading}
                onClick={() => {
                  const { history } = this.props;
                  history.push(`/student/profile/${tutor}`);
                }}>
                view teacher
              </Button>
              <Button
                disabled={loading}
                color="primary"
                onClick={() => {
                  onBook(groupSessionDetail);
                }}>
                book
              </Button>
            </ButtonGroup>
          </PortletFooter>
        </Portlet>
      </>
    );
  }
}

export default withRouter(withStyles(styles)(GroupSessionTutorBookingCard));
