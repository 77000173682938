import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Avatar,
  Chip,
  Tooltip,
  makeStyles,
  Button,
  Popover
} from '@material-ui/core';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';

import { getInitials } from 'helpers';
import {
  AssignmentRounded as LessonIcon,
  FeedbackRounded as FeedbackIcon,
  CallRounded as CallIcon,
  PublishRounded as SubmitIcon,
  CancelRounded as CancelIcon,
  WarningRounded as WarningIcon
} from '@mui/icons-material';

// Style
import styles from './styles';
import { AuthContext } from 'services/auth';
import BookingInfo from 'components/BookingInfo';
import { CallTimelineDialog } from 'components';
import { type } from 'os';

const useStyles = makeStyles(styles);

function GroupSessionBookingHistoryCard({
  groupSession,
  onSubmitFeedback,
  onViewFeedback
}) {
  const classes = useStyles();
  const { bookings, maxStudentCount, minStudentCount, id } = groupSession;
  const [data, setData] = useState({});
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const auth = useContext(AuthContext);
  const callTimelineDialogRef = useRef(null);
  const fetchData = async (info, bookingId) => {
    try {
      const [materialData, courseData] = await Promise.all([
        auth.api.materials.getMaterials(),
        auth.api.materials.getCourses(info.topic.material)
      ]);

      const material = materialData.find(
        (entry) => entry.id === info.topic.material
      );
      const course = courseData.find((entry) => entry.id === info.topic.course);
      const lesson = course?.lessons.find(
        (lesson) => lesson.id === info.topic.lesson
      );

      // Return the data as a simple object with keys
      return {
        material,
        course,
        lesson
      };
    } catch (error) {
      console.error(error);
      return { error: 'Failed to fetch data' };
    }
  };

  useEffect(() => {
    // Iterate through each booking and fetch its data
    bookings?.forEach(async (booking) => {
      const { bookingId, info } = booking;
      const bookingData = await fetchData(info, bookingId);
      setData((prevData) => ({
        ...prevData,
        [bookingId]: bookingData
      }));
    });
  }, [bookings, auth]);

  return (
    <Fragment>
      {bookings?.map((booking) => {
        const {
          bookingId,
          status,
          info,
          tutor,
          when,
          studentIds = []
        } = booking;

        // Calculate the date and relative time
        const sessionDate = DateTime.fromMillis(when || 0).toFormat(
          'd LLL h:mm a'
        );
        const relativeDate = DateTime.fromMillis(when || 0).toRelative();

        // Calculate the student count for this specific booking
        const studentCount = studentIds.length;

        const bookingData = data[bookingId] || {};

        return (
          <>
            <Popover
              id={popoverAnchor ? 'simple-popover' : undefined}
              open={Boolean(popoverAnchor)}
              anchorEl={popoverAnchor}
              onClose={() => {
                setPopoverAnchor(null);
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}>
              <BookingInfo info={booking.info} booking={booking} />
            </Popover>
            <CallTimelineDialog
              ref={callTimelineDialogRef}
              isGroupSession={true}
            />
            <Card key={bookingId} style={{ marginBottom: '16px' }}>
              <CardContent>
                <Grid container spacing={4}>
                  {/* Tutor Avatar and Link */}
                  <Grid
                    item
                    className={classes.avatarWrapper}
                    xs={12}
                    sm="auto"
                    md="auto"
                    lg="auto"
                    xl="auto">
                    <Tooltip title="Goto Profile" aria-label="Go to profile">
                      <Link to={`/student/profile/${tutor.uid}`}>
                        <Avatar
                          src={tutor.image}
                          alt={tutor.name}
                          className={classes.avatar}>
                          <Typography variant="h3" style={{ color: 'white' }}>
                            {getInitials(tutor.name || '')}
                          </Typography>
                        </Avatar>
                      </Link>
                    </Tooltip>
                  </Grid>

                  {/* Booking Information */}
                  <Grid item xs={12} sm>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="flex-start">
                      {/* Name and Booking Status */}
                      <Grid item>
                        <Typography
                          variant="h3"
                          style={{
                            marginBottom: '10px'
                          }}>
                          {bookingData?.course?.name}
                          &nbsp;
                          {bookingData?.lesson?.level}
                        </Typography>
                        <Typography variant="h3">{tutor.name}</Typography>
                        <div style={{ marginTop: '10px' }}>
                          {/* Status and Date/Time Information */}
                          <Grid container spacing={1} alignItems="center">
                            <Grid item>
                              <Chip
                                size="small"
                                label={status}
                                color="primary"
                              />
                            </Grid>
                            <Grid item>
                              <Typography color="textSecondary">
                                {`${sessionDate}, ${relativeDate}`}
                              </Typography>
                            </Grid>
                          </Grid>

                          {auth.role === 'tutor' && (
                            <div className={classes.actions}>
                              <Button
                                size="small"
                                className={classes.gutterRight}
                                variant="contained"
                                onClick={(event) =>
                                  setPopoverAnchor(event.currentTarget)
                                }
                                startIcon={<LessonIcon />}>
                                view details
                              </Button>

                              {booking?.logs && booking?.logs?.length > 0 ? (
                                <Button
                                  size="small"
                                  className={`${classes.gutterRight} ${classes.hideOnSmall}`}
                                  variant="outlined"
                                  color="primary"
                                  onClick={() =>
                                    callTimelineDialogRef.current.open(booking)
                                  }
                                  startIcon={<CallIcon />}>
                                  call log
                                </Button>
                              ) : null}
                              {booking?.students?.some(
                                (item) => item?.feedback
                              ) ? (
                                <Button
                                  size="small"
                                  variant="contained"
                                  color="primary"
                                  onClick={() =>
                                    onViewFeedback({
                                      ...booking,
                                      type: 'group'
                                    })
                                  }
                                  startIcon={<FeedbackIcon />}>
                                  view feedback
                                </Button>
                              ) : (
                                booking.status === 'pending-feedback' && (
                                  <Button
                                    size="small"
                                    disabled={
                                      Date.now() <= booking.when ||
                                      booking.when + 86400000 < Date.now()
                                    }
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      onSubmitFeedback({ ...booking, id });
                                    }}
                                    startIcon={<SubmitIcon />}>
                                    submit feedback
                                  </Button>
                                )
                              )}
                            </div>
                          )}
                          {auth.role === 'student' && (
                            <div className={classes.actions}>
                              <Button
                                size="small"
                                className={classes.gutterRight}
                                variant="contained"
                                onClick={(event) =>
                                  setPopoverAnchor(event.currentTarget)
                                }
                                startIcon={<LessonIcon />}>
                                view details
                              </Button>

                              <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                disabled={
                                  !booking?.students?.some(
                                    (item) => item?.feedback
                                  )
                                }
                                onClick={() =>
                                  onViewFeedback({ ...booking, type: 'group' })
                                }
                                startIcon={<FeedbackIcon />}>
                                view feedback
                              </Button>
                            </div>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </>
        );
      })}
    </Fragment>
  );
}

export default GroupSessionBookingHistoryCard;
