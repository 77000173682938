import { PureComponent } from 'react';

// Material UI imports
import {
  TextField,
  Typography,
  FormGroup,
  // FormControlLabel,
  // Checkbox,
  withStyles,
  Button,
  Select,
  MenuItem
} from '@material-ui/core';

// Responsive dialog
import ResponsiveDialog from 'components/ResponsiveDialog';

// Snackbar provider
import { withSnackbar } from 'notistack';

// To clean up exports
import compose from 'recompose/compose';

// To validate the form input
import validate from 'validate.js';

// Login schema
import schema from './schema';

// Component styles
import styles from './styles';

class SubmitFeedbackDialog extends PureComponent {
  constructor(props) {
    super(props);

    // Initialize the state
    this.state = {
      open: false,
      isValid: false,
      values: {},
      touched: {},
      errors: {},
      booking: undefined
    };

    // Bind functions
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.confirm = this.confirm.bind(this);

    // Bind event handlers
    this.handleChange = this.handleChange.bind(this);

    // Create the events object
    this.events = {
      close: this.close,
      confirm: this.confirm
    };

    // Set the signal to true to allow for state updates
    this.ready = true;
  }

  componentWillUnmount() {
    // Set the ready flag to false to prevent any further state changes
    this.ready = false;
  }

  // Create a open function to hook into in
  // the parent component
  open(booking) {
    this.setState({
      open: true,
      values: {
        completedAssigned: false,
        pronunciation: '',
        vocabulary: '',
        grammar: '',
        listening: '',
        behaviour: ''
      },
      errors: {},
      touched: {},
      isValid: false,
      booking
    });
  }

  // Create a close function to hook into in
  // the parent component
  close() {
    this.setState({
      open: false
    });
  }

  confirm() {
    const { isValid, values, booking } = this.state;
    const { onConfirm, enqueueSnackbar } = this.props;

    // If the form is valid, submit it
    if (isValid) {
      // Trigger the onConfirm function
      this.close();

      // Confirm the material
      onConfirm(booking, {
        ...values
      });
    } else {
      // Show an error snackbar
      enqueueSnackbar(
        'Please correctly fill out all details before submitting.',
        { variant: 'error' }
      );
    }
  }

  handleChange(event) {
    // Grab the values from the state
    const { values, touched } = this.state;

    // Get the newly changed values
    const newValues = {
      ...values,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    };

    // Validate the state's values
    const errors = validate(newValues, schema);

    // Update the state
    this.setState({
      values: newValues,
      touched: {
        ...touched,
        [event.target.name]: true
      },
      isValid: !errors,
      errors: errors || {}
    });
  }

  hasError(field) {
    // Get the errors from the state
    const { touched, errors } = this.state;
    return !!(touched[field] && errors[field]);
  }

  render() {
    // Get the current from state
    const { open, booking, values, errors, isValid } = this.state;
    const { classes, type } = this.props;

    console.log(type);

    return (
      <ResponsiveDialog
        title={`Submit details for booking with ${
          type
            ? `Group session feedback`
            : booking && booking.profile.student.name
        }`}
        events={this.events}
        open={open}
        confirmDisabled={!isValid}>
        <div className={classes.entries}>
          <div className={classes.header}>
            <Typography
              id="range-slider"
              variant="subtitle2"
              className={classes.info}>
              These details will be available to students
            </Typography>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() =>
                window.open(
                  'https://firebasestorage.googleapis.com/v0/b/eng-tutor-app-dev.appspot.com/o/feedbacksamples%2FTutor%20Feedback%20Samples.pdf?alt=media&token=5d02c143-ed77-4b32-b8bc-bb8fe238f5a6',
                  '_blank'
                )
              }>
              OPEN FEEDBACK SAMPLES
            </Button>
          </div>
          <FormGroup row>
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={values.completedAssigned || false}
                  onChange={this.handleChange}
                  name="completedAssigned"
                  color="primary"
                />
              }
              label="Student completed assigned material"
            /> */}
          </FormGroup>

          {type ? (
            <Select
              label="Select Student"
              id="select-token"
              fullWidth
              variant="outlined"
              name="student"
              style={{ marginTop: '9px' }}
              size="small"
              value={values?.student || ''}
              onChange={this.handleChange}>
              <MenuItem disabled value="Select student"></MenuItem>
              {booking?.students
                ?.filter((f) => !f.feedback)
                ?.map((item, i) => (
                  <MenuItem key={i} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
          ) : null}
          <TextField
            error={this.hasError('pronunciation')}
            fullWidth
            helperText={
              this.hasError('pronunciation') ? errors.pronunciation[0] : null
            }
            label="Pronunciation"
            name="pronunciation"
            onChange={this.handleChange}
            type="text"
            value={values.pronunciation || ''}
            variant="outlined"
            multiline
            rows={4}
          />
          <TextField
            error={this.hasError('vocabulary')}
            fullWidth
            helperText={
              this.hasError('vocabulary') ? errors.vocabulary[0] : null
            }
            label="Vocabulary"
            name="vocabulary"
            onChange={this.handleChange}
            type="text"
            value={values.vocabulary || ''}
            variant="outlined"
            multiline
            rows={4}
          />
          <TextField
            error={this.hasError('grammar')}
            fullWidth
            helperText={this.hasError('grammar') ? errors.grammar[0] : null}
            label="Grammar"
            name="grammar"
            onChange={this.handleChange}
            type="text"
            value={values.grammar || ''}
            variant="outlined"
            multiline
            rows={4}
          />
          <TextField
            error={this.hasError('listening')}
            fullWidth
            helperText={this.hasError('listening') ? errors.listening[0] : null}
            label="Listening"
            name="listening"
            onChange={this.handleChange}
            type="text"
            value={values.listening || ''}
            variant="outlined"
            multiline
            rows={4}
          />
          <Typography
            id="range-slider"
            variant="subtitle2"
            className={classes.info}>
            These details will only be available to tutors and admins
          </Typography>
          <TextField
            error={this.hasError('behaviour')}
            fullWidth
            helperText={this.hasError('behaviour') ? errors.behaviour[0] : null}
            label="Behaviour"
            name="behaviour"
            onChange={this.handleChange}
            type="text"
            value={values.behaviour || ''}
            variant="outlined"
            multiline
            rows={4}
          />
        </div>
      </ResponsiveDialog>
    );
  }
}

export default compose(withStyles(styles), withSnackbar)(SubmitFeedbackDialog);
